/* eslint-disable global-require */
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-image-lightbox/style.css';
import 'video.js/dist/video-js.css';
import {
  isMultiColorActive,
  defaultColor,
  isDarkSwitchActive,
} from './constants/defaultValues';
import { getCurrentColor, setCurrentColor } from './helpers/Utils';

// Obtener la versión actual de la aplicación desde las variables de entorno
const APP_VERSION = process.env.REACT_APP_VERSION;
const SAVED_VERSION = localStorage.getItem("app-version");

// Si la versión cambió, limpiar localStorage
if (SAVED_VERSION && SAVED_VERSION !== APP_VERSION) {
    console.log("Nueva versión detectada, limpiando Local Storage...");
    localStorage.clear();
}

localStorage.setItem("app-version", APP_VERSION);

const color =
  isMultiColorActive || isDarkSwitchActive ? getCurrentColor() : defaultColor;
  console.log(color)
setCurrentColor(color);

const render = () => {
  console.log(color)
  import(`./assets/css/sass/themes/gogo.${color}.scss`).then(() => {
    require('./AppRenderer');
  });
};
render();
