import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { adminRoot } from 'constants/defaultValues';
import { getCurrentUser, setCurrentUser } from 'helpers/Utils';
import axios from 'axios';
import { NotificationManager } from 'components/common/react-notifications';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CODE_OTP_PASSWORD
} from '../contants';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  /*  codeOtpPasswordSuccess,
   codeOtpPasswordError */
} from './actions';

import API_BASE_URL from '../../config/config';


export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) =>
  // eslint-disable-next-line no-return-await
  await axios.post(`${API_BASE_URL}/api/auth/login`, { email, password }).then((user) => user)
    .catch((error) => error);


function* loginWithEmailPassword({ payload }) {
  console.log('Payload:', payload);
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    console.log('loginUser:', loginUser.status);
    if (loginUser.status === 200) {
      console.log('token', loginUser.data);
      /* const item = { uid: loginUser.user.uid, ...currentUser }; */
      const item = {
        uid: loginUser.data.token,
        id: loginUser.data.user.id,
        title: loginUser.data.user.name,
        img: "/assets/img/profiles/abogadoperfil.png",
        date: "Last seen today 15:24",
        role: 0,
        roleUser: loginUser.data.role.id,
        permissions: loginUser.data.role.permissions.map(permission => ({
          id: permission.id,
          name: permission.name
        }))
      };
      setCurrentUser(item);
      console.log('item:', item);
      console.log('currentUser', getCurrentUser());
      yield put(loginUserSuccess(item));
      history.push(adminRoot);
    } else if (loginUser.response && loginUser.response.status === 401) {
      // Error de credenciales incorrectas (código de estado 401)
      console.log('Credenciales incorrectas:', loginUser.response.data.message);
      yield put(loginUserError(loginUser.response.data.message));
    }
    else {
      console.log('Error en el inicio de sesión:', loginUser.response.data.message,);
      yield put(loginUserError(loginUser.response.data.message));
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Error de credenciales incorrectas (código de estado 401)
      console.log('Credenciales incorrectas:', error.response.data.message);
      yield put(loginUserError(error.response.data.message));
    }
    else {
      // Otros errores (por ejemplo, problemas de red)
      console.error('Error en la solicitud de inicio de sesión:', error);
      yield put(loginUserError(error));
    }

  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (name, email, password, phone, countryId, city) =>
  /*  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error);  */
  // eslint-disable-next-line no-return-await
  await axios.post(`${API_BASE_URL}/api/auth/register`, { name, email, password, phone, countryId, city }).then((user) => user)
    .catch((error) => error);



function* registerWithEmailPassword({ payload }) {
  const { name, email, password, phone, countryId, city } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      name,
      email,
      password,
      phone,
      countryId,
      city
    );
    console.log(registerUser.data);
    if (registerUser.status === 200) {
      console.log('todo correctamente');
      /* const item = { uid: registerUser.user.uid, ...currentUser }; */
      const hola = registerUser.data.role.permissions.map(permission => ({
        id: permission.id,
        name: permission.name
      }));
      console.log('Holaaaaaaaaaaaa');
      console.log(hola);

      const item = {
        uid: registerUser.data.token,
        /* uid:'bzebm5ZQnhepuRBYAAZBbWxa1lm2', */
        id: 1,
        title: registerUser.data.user.name,
        img: "/assets/img/profiles/abogadoperfil.png",
        date: "Last seen today 15:24",
        role: 0,
        roleUser: registerUser.data.role.id,
        permissions: registerUser.data.role.permissions.map(permission => ({
          id: permission.id,
          name: permission.name
        }))
      };

      setCurrentUser(item);
      yield put(registerUserSuccess(item));
      history.push(adminRoot);
    } else if (registerUser.response && registerUser.response.status === 401) {
      // Error de credenciales incorrectas (código de estado 401)
      console.log('Credenciales incorrectas:', registerUser.response.data.message);
      yield put(registerUserError(registerUser.response.data.message));
    }
    else {
      console.log('Error en el inicio de sesión:', registerUser.response.data.message,);
      yield put(registerUserError(registerUser.response.data.message));
    }

  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  /* await auth
    .signOut()
    .then((user) => user)
    .catch((error) => error); */
  const userCurrent = getCurrentUser();
  console.log('userCurrent', userCurrent);
  console.log('userCurrentId', userCurrent.uid);
  const config = {
    headers: { Authorization: `Bearer ${userCurrent.uid}` }
  };
  const bodyParameters = {
    key: "value"
  };
  try {
    await axios.post(`${API_BASE_URL}/api/auth/logout`, bodyParameters,
      config
    ).catch((error) => console.log(error));

    setCurrentUser();

    history.push("/user/login");
  } catch (error) {
    NotificationManager.warning(
      'El servidor no ha podido cerrar sesión correctamente.',
      'Error al moento de cerrar sesion.',
      3000,
      null,
      null,
      ''
    );

  }


};

function* logout({ payload }) {
  const { history } = payload;
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  // eslint-disable-next-line no-return-await
  return await axios.post(`${API_BASE_URL}/api/MAIL/requestOTP`, { email }).then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  console.log('Payload Forgot');
  console.log(payload);
  const { email } = payload.forgotUserMail;
  const { history } = payload;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (forgotPasswordStatus.status === 200) {
      yield put(forgotPasswordSuccess('success'));
      NotificationManager.success(
        'Por favor revise su e-mail',
        'Correo enviado con Exito',
        3000,
        null,
        null,
        ''
      );
      history.push({
        pathname: '/user/otp-password',
        state: { emailPassword: email }
      });
    }
    else if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}



export function* watchCodeOtpPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(CODE_OTP_PASSWORD, codeOtpPassword);
}

const codeOtpPasswordAsync = async (email, otp) => {
  // eslint-disable-next-line no-return-await
  return await axios.post(`${API_BASE_URL}/api/MAIL/verifyOTP`, { email, otp }).then((user) => user)
    .catch((error) => error);
};

function* codeOtpPassword({ payload }) {

  const { email, otp } = payload;
  const { history } = payload;
  console.log('email, codeOtp');
  console.log(payload);
  try {
    const codeOtpPasswordStatus = yield call(codeOtpPasswordAsync, email, otp);

    console.log('OTPPPPPPPPPPP');
    console.log(codeOtpPasswordStatus);
    if (codeOtpPasswordStatus.data.status === 200) {
      NotificationManager.success(
        'El codigo otp ha sido ingresado correctamente.',
        'Codigo OTP Correcto',
        3000,
        null,
        null,
        ''
      );
      history.push({
        pathname: '/user/reset-password',
        state: { emailPassword: email }
      });
    }
    else if (codeOtpPasswordStatus.data.status !== 401) {
      NotificationManager.warning(
        'Codigo Incorrecto.',
        'Error de Codigo OTP',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(
        'Codigo Incorrecto.',
        'Error de Codigo OTP',
        3000,
        null,
        null,
        ''
      );
    }
  } catch (error) {
    NotificationManager.warning(
      error,
      'Error de Codigo OTP',
      3000,
      null,
      null,
      ''
    );
  }
}


export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}
// eslint-disable-next-line
const resetPasswordAsync = async (email, new_password) => {
  console.log('resetPassword');
  console.log(email);
  console.log(new_password);

  // eslint-disable-next-line no-return-await
  return await axios.post(`${API_BASE_URL}/api/MAIL/resetPassword`, { email, new_password }).then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { email, newPassword } = payload;
  const { history } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      email,
      newPassword
    );
    console.log('Response Reset');
    console.log(resetPasswordStatus.status);
    if (resetPasswordStatus.status === 200) {
      NotificationManager.success(
        'La contraseña se ha cambiado correctamente.',
        '¡Contraseña cambiada con exito!',
        3000,
        null,
        null,
        ''
      );
      yield put(resetPasswordSuccess('success'));
      history.push('/user/login');
    } else {
      NotificationManager.warning(
        'La contraseña no se ha cambiado ha ocurrido un error',
        'Ocurrio un error',
        3000,
        null,
        null,
        ''
      );
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {

    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchCodeOtpPassword),
  ]);
}
